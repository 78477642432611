@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16; //
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$header-hgt: 144;
$header-hgt-sp: 64;
$header-hgt-fx: 92;
$list-dot: 10; // リストのドットサイズ
$list-gap: 16; // リストの余白
$list-start: $list-dot + $list-gap;
$check: 10; // チェックリストのチェックのサイズ
$check-dot: 24; // チェックリストのドットサイズ
$check-em: calc($check / $fz-pc) * 1em;
$check-dot-em: calc($check-dot / $fz-pc) * 1em;
$check-start-em: calc($check-em + $check-dot-em);
$flow-dot: 24; // 流れのドットサイズ
$flow-num-dot: 64; // 流れ（連番）のドットサイズ
$flow-gap: 32; // 流れの余白
$flow-bd: 3; // 流れのボーダーサイズ
$flow-bd-gap: 12; // 流れのボーダー余白
$flow-start: $flow-num-dot + $flow-gap;
$flow-dot-sp: 24; // 流れのドットサイズ（SP）
$flow-num-dot-sp: 40; // 流れ（連番）のドットサイズ（SP）
$flow-gap-sp: 16; // 流れの余白（SP）
$flow-bd-sp: 2; // 流れのボーダーサイズ（SP）
$flow-bd-gap-sp: 4; // 流れのボーダー余白
$flow-start-sp: $flow-num-dot-sp + $flow-gap-sp;

$clr-wht: #fff;
$clr-sub-01: #5885AF;
$clr-sub-02: #CF727C;
$clr-secondary-dark: #E56C44;


// カラー管理
$colors: (
  clr-primary-default: #69BF69,
  clr-primary-dark: #009A69,
  clr-primary-light: #B8D66F,

  clr-secondary-default: #E79118,
  clr-secondary-dark: #E56C44,
  clr-secondary-light: #F5D64A,

  clr-bg-01: #F7F5F0,
  clr-bg-02: #DFD3C5,

  clr-sub-01: #5885AF,
  clr-sub-02: #CF727C,

  clr-mono-01: #F2F2F2,
  clr-mono-02: #DFDFE0,
  clr-mono-03: #BABDC4,
  clr-mono-04: #9599A3,
  clr-mono-05: #646B7A,
  clr-mono-06: #333841,

  clr-text-primary: #3F362C,
  clr-text-secondary: #7B5224,
  clr-text-placeholder: #ACA8A3,
  clr-text-light: #FFFFFF,

  clr-wht: #fff,
  clr-blk: #000,
);


// CSS変数
:root {
  // typography
  --ltr-space-default: normal;
  --line-height-default: 1.8;
  --line-height-hdr: 1.7;
  --line-height-none: 1;

  // font-families
  --font-default: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif; // webフォントを使わない場合
  --font-primary: YakuHanJP, "Outfit", "Zen Kaku Gothic New", sans-serif; // 本文。txt-md-jp
  --font-en: "Outfit", sans-serif;

  //カラーを出力
  @each $key, $color in $colors {
    --#{$key} : #{$color};
  }
  
  //gradation
  --gradation01: linear-gradient(227deg, #69BF69 13.15%, #B8D66F 80.74%);
  --gradation02: linear-gradient(227deg, #69BF69 13.15%, #B8D66F 80.74%);
  --gradation03: linear-gradient(90deg, #009A69 0%, #69BF69 100%);
  --gradation04: linear-gradient(92deg, #E56C44 0.75%, #E79118 99.35%);

  // animation
  --transit-default: all .4s ease-out;
  --transit-slow: all .6s ease-out;
}

// SASS Maps

$z-index: (
  "max": 99999,
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
