@use "../abstracts" as *;

/* btn
********************************************** */
.btn:where(:not(:last-child)) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: rem(14) rem(56) rem(14) rem(32);
  border-radius: 9999px; 
  width: fit-content;
  color: var(--clr-wht);
  font-size: rem(18);
  background: var(--gradation03);
  text-decoration: none;
  overflow: hidden;
  @include mq(sp) {
    padding: sprem(14) sprem(48) sprem(14) sprem(24);
  }
  @include mq(sp) {
    min-width: 100%;
  }
  &:where(:not([rel*="nofollow"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before,
  &.arrow::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(32);
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    rotate: 45deg;
    transition: var(--transit-default);
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: var(--clr-wht);
    border-radius: 50vw;
    pointer-events: none;
    opacity: 0;
    transition: var(--transit-default);
    z-index: -1;
  }
  svg {
    right: rem(24);
    fill: currentColor;
  }
  &:hover {
    color: var(--clr-primary-dark);
    &::after {
      opacity: 1;
    }
  }

}
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-primary-default);
  background-color: var(--clr-wht);
  &::after {
    top: 50%;
    left: 50%;
    background-color: var(--clr-wht);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50vw;
    z-index: -1;
    opacity: 1;
  }
  svg {
    fill: var(--clr-primary-default);
  }
  &:hover {
    color: var(--clr-wht);
    &::after {
      opacity: 0;
    }
    svg {
      fill: var(--clr-wht);
    }
  }
}

/* --- btn-other --- */
.btn-sub-01 a,
.btn-sub-02 a,
.btn-primary-dark a,
.btn-secondary-default a {
  background: var(--clr-wht);
  &::after {
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    border-radius: 0 50vw 50vw 0;
    translate: -100% 0;
    opacity: 1;
  }
  &:hover {
    &::after {
      translate: 0 0;
    }
  }
}
.btn-sub-01 a {
  color: var(--clr-sub-01);
  &::after {
    background-color: rgba(map-get($colors, "clr-sub-01"), 0.15);
  }
  &:hover {
    color: var(--clr-sub-01);
  }
}
.btn-sub-02 a {
  color: var(--clr-sub-02);
  &::after {
    background-color: rgba(map-get($colors, "clr-sub-02"), 0.15);
  }
  &:hover {
    color: var(--clr-sub-02);
  }
}
.btn-primary-dark a {
  color: var(--clr-primary-dark);
  &::after {
    background-color: rgba(map-get($colors, "clr-primary-dark"), 0.15);
  }
  &:hover {
    color: var(--clr-primary-dark);
  }
}
.btn-secondary-default a {
  color: var(--clr-secondary-default);
  &::after {
    background-color: rgba(map-get($colors, "clr-secondary-default"), 0.15);
  }
  &:hover {
    color: var(--clr-secondary-default);
  }
}

/* --- btn-cta --- */
.btn-cta {
  a {
    @include center-flex;
    gap: rem(8);
    background: var(--gradation04);
    &:hover {
      color: var(--clr-secondary-dark);
    }
  }
}
.btn-cta-lft {
  a {
    justify-content: inherit;
  }
}
.btn-cta:not(.btn-cta-arrow) {
  &::before {
    content: none;
  }
}


/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-primary-default);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}
