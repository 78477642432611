@use "../abstracts" as *;

/* default
**************************************** */
.section_pdg {
  padding-block: rem(144);
  @include mq(sp) {
    padding-block: sprem(80);
  }
}
.section_pdg-top {
  padding-top: rem(144);
  @include mq(sp) {
    padding-top: sprem(80);
  }
}
.section_pdg-btm {
  padding-bottom: rem(144);
  @include mq(sp) {
    padding-bottom: sprem(80);
  }
}

/* sm
**************************************** */
.section_pdg-sm {
  padding-block: rem(80);
  @include mq(sp) {
    padding-block: sprem(40);
  }
}
.section_pdg-sm-top {
  padding-top: rem(80);
  @include mq(sp) {
    padding-top: sprem(40);
  }
}
.section_pdg-sm-btm {
  padding-bottom: rem(80);
  @include mq(sp) {
    padding-bottom: sprem(40);
  }
}
