@use '../abstracts' as *;

/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-on .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}
.lps_parts--img_text .img_text--child .img {
  height: 100%;
}
.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  @include auto-margin;
}
.lps_parts--img_text .img_text--txt {
  position: relative;
  z-index: 10;
  @include mq(sp) {
    padding-top: rem(32);
  }
}
.lps_parts--img_text .bg-on .img_text--txt {
  padding: perc(48,1200,'%');
  background-color: var(--clr-wht);
  @include mq(sp) {
    margin-top: sprem(24);
    padding: sprem(24);
  }
}

/* --- 通常 --- */
.lps_parts--img_text .lps_parts {
  @include mq(sp, min, ps) {
    margin-top: rem(80);
  }
}
.lps_parts--img_text .lps_parts:not(:last-child) {
  margin-bottom: rem(80);
  @include mq(sp) {
    margin-bottom: sprem(56);
  }
}
.lps_parts--img_text .lps_parts--child:not(.inner-lg) {
  align-items: flex-start;
  .img_text--txt {
    @include mq(sp, min, ps) {
      margin-top: rem(56);
    }
  }
  .img_text--child .img img {
    position: relative;
    border: rem(8) solid var(--clr-wht);
    box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
    height: auto;
    width: 106.666% !important;
    max-width: inherit;
    @include mq(sp) {
      width: 100% !important;
    }
  }
  &:not(.flex-reverse) .img_text--child .img img {
    rotate: -2deg;
    transform-origin: top left;
    @include mq(sp, min, ps) {
      left: 3.333%;
    }
    @include mq(sp) {
      rotate: -1.5deg;
    }
  }
  &.flex-reverse .img_text--child .img img {
    rotate: 2deg;
    transform-origin: top right;
    @include mq(sp, min, ps) {
      right: 3.333%;
    }
    @include mq(sp) {
      rotate: 1.5deg;
    }
  }
}

/* --- L --- */
.lps_parts--img_text {
  .lps_parts:has(.lps_parts--child.inner-lg) {
    @include mq(sp, min, ps) {
      margin-block: 0;
    }
  }
  .bg-on {
    background-color: var(--clr-bg-01);
  }
}
.lps_sec:nth-child(even) {
  .lps_parts--img_text .bg-on {
    background-color: var(--clr-wht);
  }
}
.lps_parts--img_text .inner-lg {
  @include mq(sp) {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
  .img_text--img {
    position: absolute;
    top: 0;
    @include mq(sp, min, ps) {
      height: 100%;
    }
    @include mq(sp) {
      position: relative;
    }
  }
  &:not(.flex-reverse) .img_text--img {
    left: 0;
  }
  &.flex-reverse .img_text--img {
    right: 0;
  }
  .img_text--txt {
    @include mq(sp, min, ps) {
      padding-block: rem(80);
      min-height: rem(607.64);
    }
    @include mq(sp) {
      width: 86% !important;
      @include auto-margin;
    }
  }
  &:not(.flex-reverse) .img_text--txt {
    margin-left: auto;
    @include mq(sp, min, ps) {
      padding-left: perc(160,1456,'%');
      padding-right: 0;
    }
  }
  &.flex-reverse .img_text--txt {
    margin-right: auto;
    @include mq(sp, min, ps) {
      padding-left: 0;
      padding-right: perc(160,1456,'%');
    }
  }
  &.bg-on .img_text--txt {
    @include mq(sp) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: sprem(40);
    }
  }
}

@include mq(sp, min, ps) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }
  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@include mq(sp) {
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .bg-on + .bg-on {
    margin-top: sprem(-40);
  }
}