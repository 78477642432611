@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs .inner {
  padding: rem(24) 0;
  white-space: nowrap;
  @include mq(sp, min, ps) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include mq(sp) {
    padding: sprem(16) 0;
    overflow: auto;
  }
}
.breadcrumbs a {
  color: var(--clr-text-secondary);
}
.breadcrumb_last,
.breadcrumbs a:hover {
  color: var(--clr-primary-default);
}

.breadcrumbs span span:not(:last-child)::after {
  content: '';
  display: inline-block;
  @include rect(6,8);
  clip-path: polygon(0 100%, 0 0, 100% 50%);
  background-color: #ccc;
  margin: .15em .5em .15em 1em;
  clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
  background-color: var(--clr-text-secondary);
}