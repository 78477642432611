@use "../abstracts" as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: rem(1200);
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.inner-xs {
  max-width: rem(720);
}
.inner-sm {
  max-width: rem(920);
}
.inner-md {
  max-width: rem(1060);
}
.inner-lg {
  max-width: rem(1360);
}
.inner-xl {
  max-width: rem(1860);
}
.inner-960 {
  max-width: rem(960);
}
.inner-1080 {
  max-width: rem(1080);
}
.inner-1520 {
  max-width: rem(1520);
}
.inner-1680 {
  max-width: rem(1680);
}
