@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: rem(960);
  @include auto-margin;
  .ttl {
    margin-bottom: 0 !important;
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
  }
  .mce-content-body {
    @include mq(sp, min, ps) {
      margin-bottom: 0 !important;
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) {
  .faq_item {
    border: 2px solid var(--clr-bg-02);
    background-color: var(--clr-wht);
    &:not(:last-child) {
      margin-bottom: rem(40);
      @include mq(sp) {
        margin-bottom: sprem(16);
      }
    }
  }
  .ttl,
  .text {
    padding: rem(32);
    @include mq(sp) {
      padding: sprem(24);
    }
  }
  .ttl {
    color: var(--clr-text-primary);
    background-color: var(--clr-bg-02);
  }
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle {
  .faq_item {
    border-bottom: 1px solid var(--clr-bg-02);
    padding: rem(40) rem(30);
    @include mq(sp) {
      padding: sprem(24) 0;
    }
    &:not(:first-child) {
      border-top: 0;
    }
  }
  .ttl {
    cursor: pointer;
    padding-right: rem(72);
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    &::before {
      content: '';
      position: absolute;
      inset: rem(-40) rem(-30);
      @include mq(sp) {
        inset: sprem(-24);
      }
    }
  }
  .ttl .toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    @include rect(48);
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
    border-radius: 50%;
    transition: var(--transit-default);
    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(12);
      height: 2px;
      border-bottom: 2px solid;
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
  }
  .ttl:hover .toggle {
    opacity: .8;
  }
  .ttl.active .toggle {
    transform: rotate(180deg);
    background-color: var(--clr-bg-02);
    &::after {
      opacity: 0;
    }
  }
  .text {
    padding-top: rem(24);
    @include mq(sp) {
      padding-top: sprem(16);
    }
  }
}