@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: clamp-vw($fz-pc);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-padding-top: rem($header-hgt-fx);
  &::before,
  &::after {
    content: '';
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: var(--gradation03);
    pointer-events: none;
    z-index: z(max);
  }
  &::before {
    clip-path: polygon(0 0, rem(8) 0, rem(8) 100%, 0 100%);
    @include mq(sp) {
      clip-path: polygon(0 0, sprem(4) 0, sprem(4) 100%, 0 100%);
    }
  }
  &::after {
    clip-path: polygon(calc(100% - rem(8)) 0, 100% 0%, 100% 100%, calc(100% - rem(8)) 100%);
    @include mq(sp) {
      clip-path: polygon(calc(100% - sprem(4)) 0, 100% 0%, 100% 100%, calc(100% - sprem(4)) 100%);
    }
  }

  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: rem($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: min(spvw($fz-sp), rem($fz-sp));
    scroll-padding-top: rem($header-hgt-sp);
  }
  &.fixed {
    overflow-y: hidden;
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--clr-text-primary);
  height: 100%;
  padding-top: rem($header-hgt);
  background-color: var(--clr-bg-01);
  word-break: break-word;
  @include mq(sp, min, ps) {
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(med) {
    padding-top: sprem($header-hgt-sp);
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
* {
  letter-spacing: var(--ltr-space-default);
}
ul,
ol { list-style: none; }
small { 
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a:where([href="javascript:void(0);"]):hover {
  color: var(--clr-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline : none;
}
a:where([href="javascript:void(0);"]),
a:where([href="javascript:void(0);"])::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
    text-decoration: none !important;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}

/* animation
**************************************** */
.anime-scroll {
  @include anime-scroll-style;
}