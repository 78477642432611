@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: rem(960);
  @include auto-margin;
  .flow_item {
    position: relative;
    margin-inline-start: rem($flow-start);
    @include mq(sp) {
      margin-inline-start: sprem($flow-start-sp);
    }
    &:not(:last-child) {
      padding-bottom: rem(64);
      @include mq(sp) {
        padding-bottom: sprem(32);
      }
    }
    &:not(:last-child) .ttl::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(rem($flow-start) * -1 + (rem($flow-num-dot) / 2));
      translate: -50% 0;
      width: rem($flow-bd);
      background: var(--gradation02);
      @include mq(sp) {
        width: sprem($flow-bd-sp);
        left: calc(rem($flow-start-sp) * -1 + (rem($flow-num-dot-sp) / 2));
      }
    }
    &.clr-wht:not(:last-child) .ttl::after {
      background: var(--clr-wht);
    }
  }
  .ttl {
    position: static;
    color: var(--clr-primary-dark);
    &::before,
    .dot {
      float: inline-start;
      aspect-ratio: 1;
      margin-inline-start: calc(rem($flow-start) * -1);
      border-radius: 50%;
      @include mq(sp) {
        margin-inline-start: calc(rem($flow-start-sp) * -1);
      }
    }
    .dot {
      display: none;
    }
  }
  .clr-wht .ttl {
    color: var(--clr-wht);
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
    @include mq(sp) {
      &:not(:last-child) {
        margin-bottom: sprem(24);
      }
    }
  }
  .mce-content-body {
    margin-bottom: 0 !important;
    @include mq(sp, min, ps) {
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) {
  .ttl .dot {
    display: block;
    position: relative;
    left: calc(rem($flow-num-dot - $flow-dot) / 2);
    inline-size: rem($flow-dot);
    margin-block: calc((1lh - rem($flow-dot)) / 2);
    background: var(--gradation02);
    @include mq(sp) {
      left: calc(rem($flow-num-dot-sp - $flow-dot-sp) / 2);
      inline-size: rem($flow-dot-sp);
      margin-block: calc((1lh - rem($flow-dot-sp)) / 2);
    }
    &::after {
      content: '';
      position: absolute;

      top: 50%;
      left: 50%;
      translate: -50% -50%;
      background-color: var(--clr-wht);
      border-radius: 50vw;
      @include mq(sp) {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }
    }
  }
  .flow_item:not(:last-child) .ttl::after {
    margin-top: calc((rem($flow-dot) + ((1lh - rem($flow-dot)) / 2)) + rem($flow-bd-gap));
    margin-bottom: calc((1lh - rem($flow-dot)) / -2 + rem($flow-bd-gap));
    @include mq(sp) {
      margin-top: calc((sprem($flow-dot-sp) + ((1lh - sprem($flow-dot-sp)) / 2)) + sprem($flow-bd-gap-sp));
      margin-bottom: calc((1lh - sprem($flow-dot-sp)) / -2 + sprem($flow-bd-gap-sp));
    }
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num {
  .flow_item {
    counter-increment: number;
  }
  .ttl {
    color: var(--clr-text-secondary);
  }
  .ttl::before {
    content: '';
    position: relative;
    content: counter(number);
    @include center-flex;
    inline-size: rem($flow-num-dot);
    margin-block: calc((24 / 32 * 1lh - rem($flow-num-dot)) / 2); /* 見出しフォントサイズ / 番号フォントサイズ */
    text-align: center;
    color: var(--clr-wht);
    background: var(--gradation02);
    font-family: var(--font-en);
    font-size: rem(32);
    letter-spacing: 0;
    @include mq(sp) {
      inline-size: rem($flow-num-dot-sp);
      margin-block: calc((1lh - sprem($flow-num-dot-sp)) / 2);
      font-size: sprem(18);
    }
  }
  .flow_item:not(:last-child) .ttl::after {
    font-size: rem(24);
    margin-top: calc(rem($flow-num-dot) + ((1lh - rem($flow-num-dot)) / 2) + rem($flow-bd-gap));
    margin-bottom: calc((1lh - rem($flow-num-dot)) / -2 + rem($flow-bd-gap));
    @include mq(sp) {
      margin-top: calc((sprem($flow-num-dot-sp) + ((1lh - sprem($flow-num-dot-sp)) / 2)) + sprem($flow-bd-gap-sp));
      margin-bottom: sprem(16);
    }
  }
}