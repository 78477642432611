@use '../abstracts' as *;

/* lps_parts--column
********************************************** */
.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}
.lps_parts--column .img {
  flex-shrink: 0;
}
.lps_parts--column .text {
  flex: 1;
  [class^="ttl-"] {
    color: var(--clr-text-secondary);
    margin-bottom: 0.5em;
  }
}
.lps_parts--column .img + .text {
  padding-top: 6%;
  @include mq(sp) {
    padding-top: sprem(24);
  }
}
.lps_parts--column .bg-wht .text {
  padding: 6%;
  @include mq(sp) {
    padding: sprem(24);
  }
}
.lps_parts--column .grid-sp-col2 .bg-wht .text {
  @include mq(sp) {
    padding: sprem(16);
  }

}
.lps_parts--column .lps_parts--child:not(.grid-col1) table {
  width: 100%;
}
.lps_parts--column .grid-col1 .mce-box {
  padding: rem(40);
}

@include mq(sp){
  .lps_parts--column + .lps_parts--column {
    margin-top: sprem(24);
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .flex-sp-block .column_item:not(:last-child) {
    margin-bottom: rem(40);
  }
  .lps_parts--column .text.bg-wht,
  .lps_parts--column .grid-col1 .mce-box {
    padding: sprem(24);
  }
}

/* column_slide
**************************************** */
.lps_parts .column_item .mce-content-body:has( + .column_slide) {
  margin-bottom: rem(24);
}
.column_slide {
  width: 100%;
  max-width: rem(400);
  margin-inline: auto;
  .slick-arrow {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    width: rem(32);
    aspect-ratio: 1;
    background-color: var(--clr-primary-dark);
    transition: var(--transit-default);
    z-index: 10;
    @include mq(sp) {
      width: sprem(24);
    }
    &:hover {
      background-color: var(--clr-primary-default);
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(10);
      aspect-ratio: 10/16;
      clip-path: polygon(0 0, 3px 0, 100% 50%, 3px 100%, 0 100%, calc(100% - 3px) 50%);
      background-color: var(--clr-wht);
    }
  }
  .slick-prev {
    left: 0;
    rotate: -180deg;
  }
  .slick-next {
    right: 0;
  }
  .slick-track {
    display:flex !important;
  }
  .slick-slide {
    height: auto !important;
  }
}
.column_slide--item {
  background-color: var(--clr-mono-02);
  img {
    width: 100%;
    height: 100%;
  }
}
.column_slide--dots {
  margin-top: rem(16);
  @include mq(sp) {
    margin-top: sprem(8);
  }
  .slick-dots {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(16);
    @include mq(sp) {
      gap: sprem(8);
    }
  }
  .slick-dots li {
    aspect-ratio: 1;
    transition: var(--transit-default);
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .pager-img {
    display: block;
    width: 100%;
    height: 100%;
  }
}