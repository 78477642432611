@use "../abstracts" as *;

/* page_ttl
********************************************** */
.page_ttl {
  position: relative;
  padding-bottom: rem(40);
  @include mq(sp, min, ps) {
    display: flex;
    align-items: center;
    height: rem(328);
  }
  @include mq(sp) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-block: sprem(32) sprem(40);
  }
  &:has(.page_ttl--imgarea) {
    @include mq(sp, min, ps) {
      padding-right: clamp-vw(680);
    }
  }
  & > .inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(16);
  }
}
.page_ttl--textarea {
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.page_ttl-jp {
  color: var(--clr-text-secondary);
  font-size: rem(56);
  line-height: 1.5;
  @include mq(sp) {
    font-size: sprem(32);
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.05em;
  }
}
.page_ttl-en {
  display: block;
  margin-top: rem(16);
  color: var(--clr-primary-dark);
  font-size: rem(24);
  line-height: 1.7;
  &::first-letter {
    text-transform: uppercase;
  }
  @include mq(sp) {
    margin-top: sprem(8);
    font-size: sprem(16);
    line-height: 1.6;
    letter-spacing: 0.03em;
    text-align: center;
  }
}
.page_ttl--imgarea {
  @include mq(sp, min, ps) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
  @include mq(sp) {
    position: relative;
    margin-right: sprem(20);
  }
}
.page_ttl--img,
.page_ttl--deco {
  width: clamp-vw(640);
  aspect-ratio: 640/432;
  padding: rem(10);
  background-color: var(--clr-wht);
  box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
  @include mq(sp) {
    width: spvw(320);
    padding: sprem(4);
  }
}
.page_ttl--img {
  rotate: 2deg;
  transform-origin: top left;
}
.page_ttl--deco {
  position: absolute;
  top: 0;
  right: rem(-40);
  rotate: -2deg;
  transform-origin: top right;
  z-index: -1;
  @include mq(sp) {
    right: sprem(-20);
  }
  &::before {
    content: '';
    position: absolute;
    inset: rem(10);
    background-color: var(--clr-primary-light);
    @include mq(sp) {
      inset: rem(4);
    }
  }
}


/* ttl
********************************************** */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-05,
.ttl-06 {
  position: relative;
  letter-spacing: 0.1em;
  &:where(:not([class*="mgn-btm"]):not(:last-child)) {
    margin-bottom: 1em;
  }
  span {
    line-height: inherit;
    letter-spacing: inherit;
  }
  * {
    letter-spacing: 0.05em;
  }
}
.ttl-01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--clr-text-secondary);
  font-size: rem(48);
  line-height: 1.5;
  @include mq(sp) {
    font-size: sprem(30);
    align-items: center;
    text-align: center;
  }
  &:where(:not([class*="mgn-btm"]):not(:last-child)) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
  &:has(+ .ttl-02) {
    margin-bottom: rem(56);
    @include mq(sp) {
      margin-bottom: sprem(32);
    }
  }
  span {
    @include mq(sp) {
      letter-spacing: 0.06em;
    }
  }
  &.font-en {
    font-size: rem(52);
    @include mq(sp) {
      font-size: sprem(36);
    }
  }
  .subtitle {
    margin-top: rem(16);
    color: var(--clr-primary-dark);
    font-style: var(--font-en);
    font-weight: 400;
    font-size: rem(24);
    line-height: 1.7;
    @include mq(sp) {
      margin-top: sprem(4);
      font-size: sprem(18);
    }
  }
  &.clr-wht .subtitle { 
    color: var(--clr-wht);
    &::after {
      background-color: var(--clr-wht);
    }
  }
  &::before {
    content: "";
    display: block;
    width: rem(64);
    margin-bottom: rem(32);
    border-bottom: 4px solid var(--clr-primary-light);
    border-radius: 50vw;
    @include mq(sp) {
      width: sprem(40);
      margin-bottom: sprem(16);
    }
  }
}
.ttl-01-left {
  align-items: flex-start;
}
.ttl-01-sp-ctr {
  @include mq(sp) {
    align-items: center;
    text-align: center;
  }
}
.ttl-02 {
  color: var(--clr-primary-dark);
  font-size: rem(40);
  line-height: 1.6;
  @include mq(sp) {
    font-size: sprem(26);
    line-height: 1.5;
  }
  &.font-en {
    font-size: rem(48);
    @include mq(sp) {
      font-size: sprem(30);
    }
  }
}
.ttl-03 {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(22);
  }
  &.font-en {
    font-size: rem(40);
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
}
.ttl-04 {
  color: var(--clr-text-secondary);
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(18);
  }
  &.font-en {
    font-size: rem(26);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
}
.ttl-05 {
  color: var(--clr-primary-dark);
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(18);

  }
  &.font-en {
    font-size: rem(24);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
}
.ttl-06 {
  color: var(--clr-text-secondary);
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.font-en {
    font-size: rem(24);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
}

/* subtitle
********************************************** */
.subtitle,
.subtitle-sm {
  letter-spacing: 0.03em !important;
}
.subtitle {
  display: block;
  color: var(--clr-primary-default);
  font-size: rem(18);
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.txt-ctr::after {
    @include auto-margin;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48, 16, em);
  }
}
.subtitle-sm {
  display: block;
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(12);
  }
  &.font-en {
    font-size: rem(14);
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.kazari-en {
  font-size: rem(152);
  @include mq(sp) {
    font-size: sprem(56 );
  }
}

/* --- anchor-ttl --- */
.anchor-ttl {
  position: absolute;
  top: rem(-$header-hgt-fx);
}