@use "../abstracts" as *;

/* bg
********************************************** */
// 背景色を出力 
.bg-01 {
  background-color: var(--clr-bg-01);
}
.bg-02 {
  background-color: var(--clr-bg-02);
}
.bg-wht {
  background-color: var(--clr-wht);
}