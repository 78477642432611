@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  background-color: var(--clr-bg-01);
  z-index: z(header);
  transition: var(--transit-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-inline: min(vw(48),48px);
    transition: var(--transit-default);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-inline: sprem(24);
    }
  }
}
.template-contact-simple .header {
  position: absolute;
}
.header-sm {
  background-color: var(--clr-wht);
  box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
  .gnav {
    height: 100%;
  }
  .cta_deco {
    display: none;
  }
  .gnav--tel--item {
    background-color: var(--clr-bg-01);
  }
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}

/* --- logo -- */
.header--logo {
  width: clamp(230px, vw(400), 400px);
  @include mq(1700) {
    width: rem(300);
  }
  @include mq(pc) {
    width: rem(200);
  }
  @include mq(sp) {
    width: sprem(184);
    max-height: rem(56);
  }
}
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}

/* gnav
********************************************** */
.gnav {
  @include mq(sp, min, ps) {
    height: 50%;
  }
}
.gnav--menu,
.gnav--menu li,
.gnav--link {
  @include mq(sp, min, ps) {
    height: 100%;
  }
}

.gnav {
  @include mq(med, min, ps) {
    display: flex;
    align-items: center;
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem($header-hgt-sp));
    margin: 0;
    padding: sprem(32) 8%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background: var(--gradation03);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.gnav--link {
  @include mq(med, min, ps) {
    @include center-flex;
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu {
  @include mq(med) {
    margin-bottom: sprem(40);
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
  }
}
.gnav--link {
  padding: 0 min(vw(16), 16px);
  @include mq(med) {
    display: block;
    color: var(--clr-wht);
    padding: sprem(8) 0;
    font-size: sprem(16);
    min-height: sprem(50);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-primary-default);
  }
}
.gnav--menu > li > .gnav--link .gnav_link--img {
  display: none;
}

/* --- sub_nav --- */
.menu-item-has-children {
  a {
    position: relative;
    @include mq(med, min, ps) {
      &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        translate: -50% 100%;
        @include rect(24,16);
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
        background-color: var(--clr-wht);
        opacity: 0;
        transition: var(--transit-default);
        z-index: 100;
      }
    }
  }
  & > .gnav--link {
    position: relative;
    padding-right: rem(32);
    @include mq(pc) {
      padding-right: rem(24);
    }
    @include mq(med) {
      padding-right: sprem(56);
    }
    &::after {
      content: '';
      position: absolute;
      right: rem(8);
      top: 50%;
      translate: 0 -50%; 
      @include rect(12,8);
      clip-path: polygon(0 1.5px, 1.5px 0, 50% calc(100% - 3px), calc(100% - 1.5px) 0, 100% 1.5px, 50% 100%);
      background-color: var(--clr-primary-default);
      @include mq(pc) {
        right: rem(4);
      }
    }
  }
}
.head_sub_nav--wrap {
  @include mq(med, min, ps) {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: max-content;
    white-space: nowrap;
    padding-top: rem(16);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default);
    z-index: 99;
  }
  @include mq(med) {
    display: none;
    translate: 0 sprem(-8);
  }
}
.gnav--menu .menu-item-has-children:hover a::before,
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med, min, ps) {
    display: flex;
    flex-wrap: wrap;
    gap: rem(16);
    padding: rem(48) rem(40);
    background-color: var(--clr-wht);
    box-shadow: 0px 0px 16px 0px rgba(63, 54, 44, 0.10);
  }
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav a:not(.gnav_ttl) {
  @include mq(med) {
    display: flex;
    align-items: flex-start;
    gap: sprem(8);
    padding-block: sprem(5);
    min-height: sprem(36);
    &::before {
      content: "";
      display: block;
      width: sprem(5);
      aspect-ratio: 5/8;
      margin-top: 0.65em;
      clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
      background-color: currentColor;
    }
  }
}
.head_sub_nav a:not(.gnav_ttl):has(.gnav_link--img) {
  @include mq(med, min, ps) {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: rem(16) rem(24);
    width: rem(240);
    aspect-ratio: 240/160;
    color: var(--clr-wht);
    border-radius: rem(8);
    background-color: var(--clr-mono-03);
    overflow: hidden;
    &::before {
      content: none;
    }
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.4) 100%);
      pointer-events: none;
      z-index: 1;
    }
    span {
      position: relative;
      z-index: 2;
    }
    .gnav_link--img {
      position: absolute;
      inset: 0;
      z-index: 1;
      transition: var(--transit-default);
    }
    &:hover {
      color: var(--clr-wht);
      .gnav_link--img {
        scale: 1.08;
      }
    }
  }
}
.head_sub_nav a:not(:has(.gnav_link--img)):not(.gnav_ttl) {
  @include mq(sp, min, ps) {
    display: flex;
    gap: rem(16);
    &::after {
      content: '';
      display: block;
      width: rem(8);
      aspect-ratio: 8/12;
      clip-path: polygon(0 1.5px, 1.5px 0, 100% 50%, 1.5px 100%, 0 calc(100% - 1.5px), calc(100% - 3px) 50%);
      background-color: var(--clr-primary-default);
    }
    &:hover {
      color: var(--clr-primary-default);
    }
  }
}
.head_sub_nav a.gnav_ttl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(24);
  padding-inline: 0 rem(48);
  color: var(--clr-text-secondary);
  font-size: rem(32);
  line-height: 1.7;
  letter-spacing: 0.05em;
  &::after {
    content: "";
    display: block;
    width: rem(40);
    border-bottom: 4px solid var(--clr-primary-light);
    border-radius: 50vw;
  }
  @include mq(med) {
    display: none;
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: rem(-12);
    @include sprect(50);
    transition: var(--transit-default);
    color: var(--clr-wht);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav_btn,
.gnav_btn-cta {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(64);
    cursor: pointer;
    z-index: 9999;
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
    transition: var(--transit-default);
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24, 16);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-primary-default);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-7)) rotate(45deg);
    }
  }
}

/* --- gnav_btn-cta --- */
@include mq(med) {
  .gnav_btn-cta > a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: sprem(8);
    height: 100%;
    gap: sprem(6);
    font-size: sprem(12);
    line-height: 1.6;
    color: var(--clr-wht);
    svg {
      fill: currentColor;
    }
  }
  .gnav_btn-tel {
    right: sprem(128);
    & > a {
      background: var(--gradation03);
    }
    svg {
      width: sprem(12);
      height: auto;
    }
  }
  .gnav_btn-reserve {
    right: sprem(64);
    & > a {
      background: var(--gradation04);
    }
    svg {
      width: sprem(21);
      height: auto;
    }
  }
  .gnav_btn-telbox {
    position: absolute;
    left: 50%;
    bottom: sprem(-16);
    translate: -50% 100%;
    width: max-content;
    padding: sprem(12);
    background-color: var(--clr-wht);
    box-shadow: 2px 2px 16px 0px rgba(86, 72, 60, 0.20);
    border-radius: sprem(12);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: inherit;
    }
    .gnav--tel--close {
      position: absolute;
      top: 0;
      right: 0;
      translate: 50% -50%;
      width: sprem(24);
      aspect-ratio: 1;
      background-color: var(--clr-mono-04);
      border-radius: 50vw;
      z-index: 9999;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        width: 2px;
        height: sprem(16);
        background-color: var(--clr-wht);
      }
      &::before {
        rotate: 45deg;
      }
      &::after {
        rotate: -45deg;
      }
    }
    .gnav--tel--item {
      justify-content: flex-start;
      height: sprem(30);
      padding: sprem(8) sprem(12);
      background-color: var(--clr-bg-01);
      border-radius: sprem(8);
    }
  }
}


/* gnav--cta
**************************************** */
.gnav--cta {
  @include mq(med, min, ps) {
    display: flex;
    align-items: center;
    gap: min(vw(32), 32px);
    margin-left: min(vw(16), 16px);
    @include mq(pc) {
      gap: min(vw(16), 16px);
    }
  }
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.cta_deco {
  color: var(--clr-secondary-dark);
  @include center-flex;
  gap: rem(8);
  padding-bottom: rem(12);
  font-size: clamp(12px,vw(20),16px);
  line-height: 1.8;
  white-space: nowrap;
  svg {
    width: clamp(12px,vw(20),16px);
  }
}
/* --- gnav--tel --- */
.gnav--tel .cta_deco {
  color: var(--clr-primary-dark);
}
.gnav--tel--item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: rem(10);
  padding-inline: min(vw(16), 16px);
  background-color: var(--clr-wht);
  border-radius: rem(3);
  transition: var(--transit-default);
  &:not(:last-child) {
    margin-bottom: rem(4);
  }
  dt {
    font-size: clamp(12px,vw(20),16px);
    line-height: 1.8;
    &.men {
      color: var(--clr-sub-01);
    }
    &.women {
      color: var(--clr-sub-02);
    }
  }
  dd {
    font-family: var(--font-en);
    font-weight: 400;
    font-size: clamp(14px,vw(24),24px);
    line-height: 1;
  }
}
.gnav--tel .tel {
  font-size: rem(20);
}
.gnav--tel .num {
  font-size: rem(24);
}
.gnav--tel .hours {
  font-size: rem(14);
}

.gnav--contact {
  position: relative;
}
.gnav--contact a {
  padding-inline: rem(40);
  font-size: clamp(12px,vw(20),16px);
  width: 100%;
  @include mq(1700) {
    padding-inline: rem(24);
  }
  @include mq(pc) {
    padding-inline: rem(16);
  }
  svg {
    width: rem(28) !important;
    @include mq(pc) {
      width: rem(20) !important;
    }
  }
  &::before {
    content: none;
  }
  @include mq(med, min, ps) {
    border-radius: rem(8);
    &::after {
      border-radius: rem(6);
    }
  }
}

@include mq(sp) {
  .gnav--cta,
  .gnav--bottom {
    padding-top: sprem(32);
    border-top: 1px solid var(--clr-wht);
  }
  .gnav--cta {
    margin-bottom: sprem(32);
  }
  .gnav--cta .cta_deco {
    font-size: sprem(16);
    color: var(--clr-wht) !important;
    svg {
      width: sprem(13) !important;
    }
  }
  .gnav_btn-sp a,
  .gnav--tel--item {
    @include center-flex;
    gap: sprem(8);
    height: sprem(56);
    background-color: var(--clr-wht);
    border-radius: sprem(8);
  }
  .gnav--contact a {
    color: var(--clr-secondary-dark);
  }
  .gnav--tel--item {
    position: relative;
    &:not(:last-child) {
      margin-bottom: sprem(8);
    }
    dt {
      display: flex;
      align-items: center;
      gap: sprem(8);
      svg {
        width: sprem(13);
      }
    }
  }
  .gnav--tel a {
    color: var(--clr-text-secondary);
  }
  .gnav--catalog a {
    color: var(--clr-primary-dark);
    svg {
      width: sprem(24);
    }
  }
  .gnav--bottom,
  .gnav--bottom a {
    color: var(--clr-wht);
  }
}
