@use '../abstracts' as *;

/* lps_parts--cta
********************************************** */
.lps_parts--cta {
  &:has(+ .home_footer_toplink) {
    margin-bottom: 0 !important;
  }
  .parts_cta--box {
    position: relative;
    padding: min(vw(80), 80px) min(vw(160), 160px) min(vw(88), 88px);
    border-radius: rem(56);
    background-color: var(--clr-wht);
    box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
    @include mq(1600) {
      padding-inline: vw(80);
    }
    @include mq(sp) {
      padding: sprem(48) sprem(24) sprem(32);
      border-radius: sprem(24);
    }
  }
  .parts_cta--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: min(vw(80), 80px);
    @include mq(1600) {
      gap: vw(40);
    }
    @include mq(sp) {
      flex-direction: column;
      gap: sprem(32);
    }
  }
  .parts_cta--left {
    @include mq(sp) {
      width: 100%;
    }
  }
  .parts_cta--txt {
    font-size: rem(18);
  }
  .parts_cta--ttl {
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50% -58.333%;
    color: var(--clr-primary-dark);
    font-size: clamp-vw(112);
    letter-spacing: 0.03em;
    white-space: nowrap;
    @include lh(96,112);
    @include mq(sp) {
      font-size: sprem(48);
    }
  }
}
.lps_sec:nth-child(even) {
  .parts_cta--box {
    background-color: var(--clr-bg-01);
  }
}
.parts_cta--subttl {
  display: flex;
  align-items: center;
  gap: rem(16);
  color: var(--clr-primary-dark);
  font-size: rem(24);
  margin-bottom: rem(24);
  @include mq(sp) {
    font-size: sprem(18);
    margin-bottom: sprem(16);
  }
  &::before {
    content: '';
    display: block;
    height: rem(40);
    width: 4px;
    background-color: var(--clr-primary-light);
    border-radius: 50vw;
    @include mq(sp) {
      height: sprem(32);
    }
  }
}
.parts_cta--tel:not(:last-child) {
  margin-bottom: rem(24);
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.cta_tel--box {
  display: flex;
  align-items: center;
  gap: rem(16);
  @include mq(sp) {
    gap: sprem(8);
  }
}
.cta_tel--ttl {
  display: flex;
  align-items: center;
  gap: rem(16);
  font-size: clamp-vw(24);
  @include mq(sp) {
    font-size: sprem(16);
    gap: sprem(8);
  }
  svg {
    width: clamp-vw(20);
    @include mq(sp) {
      width: sprem(12);
    }
  }
}
.cta_tel--link {
  color: var(--clr-text-secondary);
  font-size: clamp-vw(40);
  line-height: 1;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.cta_tel--ttl {
  &.men {
    color: var(--clr-sub-01);
  }
  &.women {
    color: var(--clr-sub-02);
  }
}
.cta_tel--time {
  font-size: rem(14);
  line-height: 1.8;
  @include mq(sp, min, ps) {
    margin-top: rem(4);
  }
}
.parts_cta--right {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: vw(32);
    flex: 1;
  }
  @include mq(sp) {
    width: 100%;
  }
}
.parts_cta--link {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: rem(16);
  padding: vw(40);
  background-color: var(--clr-bg-01);
  border-radius: rem(16);
  @include mq(sp) {
    width: 100%;
    gap: sprem(12);
    border-radius: sprem(16);
    padding: sprem(16);
    &:not(:last-child) {
      margin-bottom: sprem(12);
    }
  }
  & > svg {
    width: clamp-vw(56);
    height: auto;
    @include mq(sp) {
      width: sprem(24);
    }
  }
  .btn,
  .btn a {
    width: 100%;
  }
  .btn {
    @include mq(sp) {
      width: 80%;
      margin-inline: auto;
    }
  }
  .btn a {
    padding-block: rem(20);
    font-size: rem(20);
    line-height: 1.6;
    @include mq(sp) {
      padding-block: sprem(12);
      font-size: sprem(14);
      justify-content: center;
    }
  }
  &.reserve {
    color: var(--clr-secondary-dark);
  }
  &.catalog {
    color: var(--clr-primary-dark);
  }
}
.cta_link-ttl {
  color: currentColor;
  font-size: clamp-vw(24);
  padding-bottom: 0;
  & > svg {
    width: clamp-vw(18) !important;
  }
}

/* --- Loopsパーツのとき --- */
.lps_sec .lps_parts--cta {
  .parts_cta--box {
    max-width: rem(1360);
    padding-inline: vw(80);
  }
  .cta_tel--ttl {
    font-size: rem(20);
  }
  .cta_tel--link {
    font-size: rem(32);
  }
  .cta_link-ttl {
    font-size: rem(20);
  }
}
.lps_parts + .lps_parts--cta {
  padding-top: rem(80);
}

/* --- animation --- */
.parts_cta--link {
  &.reserve {
    transition-delay: 0.3s;
  }
  &.catalog {
    transition-delay: 0.6s;
  }
}