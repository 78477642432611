@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  @include mq(sp) {
    overflow: auto;
  }
}
.anchor_link--list {
  @include mq(sp) {
    flex-wrap: inherit;
    white-space: nowrap;
    width: 100%;
    margin-inline: calc(50% - 50vw);
    padding-inline: 7%;
    overflow: auto;
  }
}
.anchor_link--list li {
  min-height: rem(96);
  @include mq(sp) {
    width: auto;
    min-height: sprem(64);
    &:first-child {
      margin-left: sprem(-16);
    }
    &:last-child {
      margin-right: sprem(-10);
    }
  }
}
.anchor_link--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em 2.5em;
  line-height: var(--line-height-hdr);
  @include mq(sp) {
    padding: sprem(14) sprem(24) sprem(14) sprem(16);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: rem(24);
    transform: translateY(-50%);
    @include rect(12, 6);
    background-color: var(--clr-text-primary);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transition: var(--transit-default);
    @include mq(sp) {
      right: sprem(10);
    }
  }
  &:hover {
    color: var(--clr-primary-default);
    &::before {
      background-color: var(--clr-primary-default);
    }
  }
}
