@use '../abstracts' as *;

/* --- pc or sp --- */
@include mq(sp, min, ps) {
  .pc-none {
    display: none !important;
  }
}
@include mq(sp) {
  .sp-none {
    display: none !important;
  }
}