@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: minmax(0,1fr) rem(264);
  gap: rem(80);
  @include mq(med) {
    grid-template-columns: repeat(1,minmax(0,1fr));
    gap: sprem(40);
  }
}

/* main_column
**************************************** */

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  background-color: var(--clr-wht);
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link {
  &:where(:not([href="javascript:void(0);"]):hover) {
    color: var(--clr-primary-default);
  }
  &:hover svg {
    fill: var(--clr-primary-default);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.post--date {
  color: var(--clr-text-secondary);
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(2);
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(13);
  background-color: var(--clr-primary-default);
  color: var(--clr-wht);
  padding-inline: 1em;
  border-radius: rem(4);
}
.cat_list a:hover {
  opacity: .7;
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: rem(64);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.side--ttl {
  letter-spacing: .2em;
  line-height: 1;
  margin-bottom: rem(16);
}
.side--ttl span {
  display: block;
  margin-top: rem(8);
  line-height: 1;
  letter-spacing: .15em;
  text-transform: uppercase;
  opacity: .5;
}

/* --- list --- */
.side--list a {
  display: block;
}
.side--list .children a {
  position: relative;
  padding-left: 1.5em;
  &::before {
    content: '';
    position: absolute;
    top: .75em;
    left: 0;
    display: block;
    width: 1em;
    border-top: solid 1px;
  }
}

/* --- post --- */
.posts-side {
  .post {
    display: grid;
    grid-template-columns: rem(64) minmax(0,1fr);
    gap: rem(16);
    align-items: center;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  .post--date {
    margin-bottom: 0;
  }
  .post--ttl {
    line-height: var(--line-height-hdr);
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
  .archive_list {
    position: relative;
    &:not(:last-child) {
      margin-right: rem(24);
    }
  }
  .archive_list a {
    display: block;
    padding: .25em 1em;
    text-align: left;
    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-bg-02);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  .archive_list--label {
    margin-right: rem(8);
  }
  .archive_list--btn {
    cursor: pointer;
    border: none;
    outline: none;
    appearance: none;
    padding: .25em 4em .25em 2em;
    background-color: var(--clr-wht);
    border: 1px solid var(--clr-mono-02);
    color: inherit;
    font-family: var(--font-primary);
    font-size: rem(16);
    line-height: var(--line-height-default);
    font-weight: 500;
    transition: var(--transit-default);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: .4em;
      right: .8em;
      margin: auto;
      width: .6em;
      height: .6em;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  .active .archive_list--btn::after {
    bottom: -.2em;
    transform: rotate(225deg);
  }
  .archive_list--menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: var(--clr-wht);
    border: 1px solid var(--clr-mono-02);
    visibility: hidden;
    margin-top: -1px;
    opacity: 0;
    transition: var(--transit-default);
  }
  .active .archive_list--menu {
    visibility: visible;
    opacity: 1;
  }
}

/* blog
**************************************** */
.posts-blog {
  .post {
    display: grid;
    gap: rem(40);
    padding-block: rem(48);
    border-bottom: 1px solid var(--clr-mono-02);
    @include mq(sp, min, ps) {
      grid-template-columns: rem(280) minmax(0,1fr);
      align-items: center;
    }
    @include mq(sp) {
      gap: sprem(24);
      padding-block: sprem(32);
    }
    &:first-child {
      border-top: 1px solid var(--clr-mono-02);
    }
  }
  .post--txtarea {
    flex: 1;
    word-break: break-word;
  }
  .post--ttl {
    font-size: rem(18);
  }
}

/* news
**************************************** */
.archive-news {
  .ttl-05 {
    padding-left: rem(8);
    padding-bottom: rem(8);
    border-bottom: 2px solid currentColor;
    @include mq(sp) {
      padding-left: sprem(4);
      padding-bottom: sprem(4);
      font-size: sprem(20);
    }
  }
}
.news_category--list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: rem(16);
  padding-inline: rem(8);
  a {
    display: inline-block;
    vertical-align: middle;
    font-size: rem(16);
    line-height: 2;
    background-color: var(--clr-primary-default);
    color: var(--clr-wht);
    padding-inline: 1.25em;
    border-radius: rem(4);
  }
}

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht);
}

/* --- posts-news --- */
.posts-news {
  .post {
    gap: rem(40);
    padding-bottom: rem(16);
    padding-top: rem(16);
    border-bottom: 1px solid var(--clr-mono-02);
    &:first-child {
      border-top: 1px solid var(--clr-mono-02);
    }
    @include mq(sp) {
      display: block;
    }
  }
  .post--info {
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post--txtarea {
    flex: 1;
  }
  .post--ttl {
    font-size: rem(18);
  }
}

/* --- lineup_item --- */
.post-lineup {
  --clr-lineup: var(--clr-primary-dark);

  display: grid;
  align-items: center;
  padding: rem(40);
  background-color: var(--clr-bg-01);
  border-radius: rem(8);
  border-left: rem(8) solid var(--clr-lineup);
  box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
  overflow: hidden;
  z-index: 1;
  @include mq(sp) {
    padding: sprem(24) sprem(24) sprem(32);
    box-shadow: 2px 2px 4px 0px rgba(86, 72, 60, 0.20);
  }
  a {
    z-index: 10;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background-color: var(--clr-lineup);
    border-radius: 0 rem(8) rem(8) 0;
    pointer-events: none;
    translate: -100% 0;
    transition: var(--transit-slow);
    pointer-events: none;
    opacity: 0.1;
  }
  &:hover {
    &::after {
      translate: 0 0;
    }
    .post_lineup--arrow {
      background-color: var(--clr-wht);
      &::after {
        background-color: var(--clr-lineup);
      }
    }
  }
}
.post_lineup--deco {
  top: rem(32);
  right: rem(32);
  z-index: 1;
  @include mq(sp) {
    top: sprem(16);
    right: sprem(16);
  }
  img {
    max-height: rem(56);
    height: 100%;
    width: auto;
    @include mq(sp) {
      max-width: sprem(130);
    }
  }
}
.post_lineup--img {
  max-width: min(vw(256),256px);
  top: rem(32);
  bottom: 0;
  z-index: 1;
  @include mq(sp) {
    right: sprem(8);
    max-width: sprem(128);
  }
  img {
    width: 100%;
    height: 100%;
    object-position: bottom;
  }
}
.post_lineup--content {
  position: relative;
  z-index: 2;
}
.post_lineup--use {
  width: fit-content;
  padding: rem(11) rem(10) rem(10);
  margin-bottom: rem(16);
  color: var(--clr-wht);
  font-size: clamp(20px, vw(24), 24px);
  line-height: 1;
  background-color: var(--clr-lineup);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.post_lineup--ttl {
  color: var(--clr-lineup);
  font-size: rem(32);
  letter-spacing: 0.05em;
  line-height: 1.6;
  margin-bottom: rem(24);
  @include mq(sp) {
    font-size: sprem(20);
    letter-spacing: 0.03em;
    margin-bottom: sprem(12);
  }
}
.post_lineup--description {
  font-size: rem(16);
  line-height: 1.8;
  @include mq(sp) {
    font-size: sprem(14);
    padding-right: sprem(96);
  }
}
.post_lineup--arrow {
  position: absolute;
  right: rem(32);
  bottom: rem(32);
  width: min(vw(56),56px);
  aspect-ratio: 1;
  background-color: var(--clr-lineup);
  border: 2px solid var(--clr-lineup);
  border-radius: 50vw;
  transition: var(--transit-slow);
  z-index: 3;
  @include mq(sp) {
    right: rem(24);
    bottom: rem(24);
    width: sprem(32);
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: rem(8);
    aspect-ratio: 8/12;
    clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
    background-color: var(--clr-wht);
    transition: var(--transit-slow);
  }
}
@include mq(sp, min, ps) {
  .post-lineup.layout-left {
    padding-left: min(vw(232),232px);
    .post_lineup--img {
      left: 0;
    }
    .post_lineup--description {
      padding-right: min(vw(88),88px);
    }
  }
  .post-lineup.layout-right {
    padding-right: min(vw(224),224px);
    .post_lineup--img {
      right: min(vw(90),90px);
    }
  }
}

.archive-line-up {
  .lps_sec {
    background-image: none !important;
  }
  .posts-lineup {
    gap: vw(40);
    @include mq(sp) {
      gap: sprem(24);
    }
  }
  .post-lineup {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: rem(32) rem(24) rem(24);
    @include mq(sp, min, ps) {
      height: clamp(240px, vw(300), 300px);
    }
    @include mq(sp) {
      padding: sprem(16);
    }
  }
  .post_lineup--img {
    left: rem(8);
    max-width: min(vw(220),220px);
    @include mq(sp, min, ps) {
      top: rem(72);
    }
    @include mq(sp) {
      left: 0;
      max-width: sprem(88);
    }
  }
  .post_lineup--deco {
    position: static;
    margin-bottom: rem(8);
    @include mq(sp) {
      margin-bottom: sprem(4);
      order: 2;
    }
  }
  .post_lineup--deco img {
    max-height: max(40px, vw(56));
    @include mq(sp) {
      max-width: sprem(80);
      max-height: sprem(28);
    }
  }
  .post_lineup--content {
    @include mq(sp) {
      order: 1;
    }
  }
  .post_lineup--use {
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post_lineup--arrow {
    @include mq(sp, min, ps) {
      right: rem(24);
      bottom: rem(24);
      width: min(vw(56),56px);
    }
    @include mq(sp) {
      display: block;
      position: relative;
      right: 0;
      bottom: 0;
      order: 3;
    }
  }
}

@include mq(sp, min, ps) {
  @include mq(pc) {
    .post_lineup--ttl,
    .post_lineup--description {
      br {
        display: none;
      }
    }
  }
}

/* shop
**************************************** */
.area_anchor--list {
  display: grid;
  grid-template-columns: repeat(8,1fr);
  gap: rem(16);
  @include mq(sp) {
    grid-template-columns: repeat(4,1fr);
    gap: sprem(8);
  }
  li a {
    display: grid;
    place-items: center;
    padding-block: rem(4);
    color: var(--clr-wht);
    background-color: var(--clr-primary-dark);
    border: 2px solid var(--clr-primary-dark);
    border-radius: rem(4);
    &:hover {
      color: var(--clr-primary-dark);
      background-color: var(--clr-wht);
    }
  }
}

.shop_area {
  &:not(:last-child) {
    margin-bottom: rem(64);
  }
  .ttl-05 {
    padding-left: rem(8);
    padding-bottom: rem(8);
    border-bottom: 2px solid currentColor;
    @include mq(sp) {
      padding-left: sprem(4);
      padding-bottom: sprem(4);
      font-size: sprem(20);
    }
  }
}
.shop_cat {
  li {
    padding: rem(6) rem(12);
    font-size: rem(14);
    line-height: 1;
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
    border-radius: rem(4);
    &[data-slug*="men"] {
      background-color: var(--clr-sub-01);
    }
    &[data-slug*="women"] {
      background-color: var(--clr-sub-02);
    }
  }
}

.post-shop {
  padding-bottom: rem(24);
  border-bottom: 1px solid var(--clr-text-placeholder);
  &:not(:last-child) {
    margin-bottom: rem(24);
  }
}
.shop_info {
  margin-top: rem(4);
  line-height: 1.4;
}
