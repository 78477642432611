@use "../abstracts" as *;

/* hero
********************************************** */
.hero {
  position: relative;
  padding-top: rem(16);
  padding-bottom: rem(64);
  overflow: hidden;
}
.hero_slide {
  margin-bottom: rem(48);
  @include mq(sp) {
    width: 80%;
    margin-bottom: sprem(40);
    @media screen and (max-height: 580px) {
      margin-bottom: sprem(24);
    }
  }
}
.hero_slide--item {
  border: rem(12) solid var(--clr-wht);
  box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
  rotate: 0deg;
  transition: 0.8s rotate ease-in-out;
  @include mq(sp) {
    border: rem(6) solid var(--clr-wht);
    aspect-ratio: 400/600;
    @media screen and (max-height: 580px) {
      aspect-ratio: 400/500;
      overflow: hidden;
    }
  }
}
.hero_slide--img {
  @include mq(sp) {
    height: 100%;
    img {
      width: 100% !important;
      height: 100%;
      object-fit: cover;
      @media screen and (max-height: 580px) {
        object-position: top;
      }
    }
  }
}
.hero_slide--txtarea {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: rem(56);
  padding-left: rem(108);
  @include mq(sp) {
    gap: sprem(24);
    justify-content: flex-end;
    padding-inline: 7%;
    padding-bottom: sprem(48);
    @media screen and (max-height: 580px) {
      padding-bottom: sprem(32);
    }
  }
}
.hero_slide--ttl {
  color: var(--clr-wht);
  font-size: rem(48);
  line-height: 1.5;
  @include mq(sp) {
    font-size: sprem(28);
    letter-spacing: 0.03em;
    text-shadow: 0 0 12px rgba(0,0,0,0.6);
  }
}
#hero_slide {
  .hero_slide--wrap {
    margin-bottom: rem(48);
    @include mq(sp) {
      margin-bottom: sprem(24);
      @media screen and (max-height: 580px) {
        margin-bottom: sprem(16);
      }
    }
  }
  .swiper-slide-prev {
    rotate: 2deg;
  }
  .swiper-slide-next {
    rotate: -2deg;
  }
  .swiper-pagination {
    @include center-flex;
    gap: rem(16);
  }
  .swiper-pagination-bullet {
    display: block;
    width: rem(8);
    aspect-ratio: 1;
    border-radius: 50vw;
    background-color: var(--clr-text-placeholder);
    transition: var(--transit-default);
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: var(--clr-primary-default);
    pointer-events: none;
  }
}

/* --- animation --- */
.hero {
  .hero_slide--item,
  .swiper-pagination,
  .hero_news {
    @include anime-scroll-before;
    transition: translate 1.5s ease,
                opacity 1.5s ease,
                rotate 0.6s ease-in-out;;
  }
  .hero_slide--item.slide02 {
    transition-delay: 0.3s
  }
  .hero_slide--item.slide-last {
    transition-delay: 0.6s
  }
  .swiper-pagination {
    transition-delay: 0.6s
  }
  .hero_news {
    transition-delay: 0.6s
  }
}
/* --- animation --- */
.hero.loaded {
  .hero_slide--item,
  .swiper-pagination,
  .hero_news {
    @include anime-scroll-after;
  }
}

/* hero_news
********************************************** */
.hero_news {
  padding: rem(24) rem(64);
  border-radius: 50vw;
  background-color: var(--clr-wht);
  @include mq(sp) {
    padding: sprem(10) sprem(24);
  }
}
.hero_news--ttl {
  position: relative;
  display: block;
  margin-right: rem(32);
  @include mq(sp) {
    margin-right: sprem(16);
  }
  a {
    color: var(--clr-primary-dark);
    font-size: rem(20);
    line-height: 1.6;
    padding-right: rem(36);
    @include mq(sp) {
      font-size: sprem(16);
      padding-right: sprem(19);
    }
    &:hover {
      color: var(--clr-secondary-dark);
    }
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: var(--clr-primary-light);
    border-radius: 50vw;
    @include mq(sp) {
      width: sprem(3);
    }
  }
}
.hero_news--list {
  flex: 1;
}
.hero_news--item .post {
  @include mq(sp) {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
  }
}
.hero_news--item .post--info {
  @include mq(sp) {
    line-height: 1.4;
    gap: 0 sprem(12);
  }
}
.hero_news--item .post--ttl {
  @include mq(sp) {
    @include clip-txt(2);
    line-height: 1.6;
  }
}

/* home_message
********************************************** */
.home_message {
  overflow: clip;
}
.home_message--photo {
  position: sticky;
  top: rem($header-hgt-fx);
  @include mq(sp) {
    top: sprem($header-hgt-sp);
  }
}
.home_message--photobox {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100vh - rem($header-hgt-fx));
  background-image: url(/wp/wp-content/themes/standard_sass/images/bg-deco01.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: rem(800) auto;
  background-color: var(--clr-bg-01);
  z-index: 1;
  @include mq(sp) {
    height: calc(100vh - rem($header-hgt-sp));
    background-size: 80% auto;
  }
}
.home_message--deco {
  position: absolute;
  border: rem(10) solid var(--clr-wht);
  box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
  @include mq(sp) {
    border: sprem(5) solid var(--clr-wht);
  }
  &.deco1 {
    @include mq(sp, min, ps) {
      top: vw(152);
      left: rem(-10);
      width: vw(458);
    }
    @include mq(sp) {
      bottom: 10%;
      left: sprem(-5);
      width: spvw(160);
    }
  }
  &.deco2 {
    @include mq(sp, min, ps) {
      top: vw(32);
      left: vw(514);
      width: vw(260);
    }
    @include mq(sp) {
      top: 10%;
      left: 8%;
      width: spvw(80);
    }
  }
  &.deco3 {
    @include mq(sp, min, ps) {
      bottom: vw(320);
      right: rem(-10);
      width: vw(340);
    }
    @include mq(sp) {
      top: 5%;
      right: sprem(-5);
      width: spvw(180);
    }
  }
  &.deco4 {
    @include mq(sp, min, ps) {
      bottom: vw(40);
      right: vw(250);
      width: vw(220);
    }
    @include mq(sp) {
      bottom: 5%;
      right: 8%;
      width: spvw(100);
    }
  }
}
.home_message--ttlarea {
  position: relative;
  z-index: 2;
  display: grid;
  align-items: center;
  height: calc(125vh - rem($header-hgt-fx));
  padding-top: 35vh;
}
.home_message--ttl {
  position: relative;
  color: var(--clr-primary-dark);
  font-size: clamp-vw(56);
  letter-spacing: 0.05em;
  z-index: 10;
  @include mq(sp) {
    font-size: sprem(32);
    line-height: 1.4;
  }
}
.home_message--content {
  position: relative;
  z-index: 2;
  background-color: rgba($clr-wht, 0.8);
  @include mq(sp) {
    background-color: rgba($clr-wht, 0.85);
  }
}
.home_message--scrolltxt {
  margin-bottom: rem(80);
  margin-inline: auto;
  @include mq(sp) {
    margin-bottom: sprem(64);
  }
}
.home_message--txt {
  text-align: center;
  .line {
    width: fit-content;
    margin-inline: auto;
    color: var(--clr-text-placeholder);
    font-size: rem(20);
    line-height: 2.3;
    @include mq(sp) {
      font-size: sprem(15);
    }
  }
  .txt-end {
    margin-bottom: 2.3em;
    @include mq(sp) {
      margin-bottom: 1.8rem;
    }
  }
}
.home_message--txt.mask {
  position: absolute;
  top: 0;
  width: 100%;
  .line {
    color: var(--clr-blk);
    opacity: 0.8;
    transition: var(--transit-default);
  }
}
.home_message--endttl {
  margin-bottom: rem(80);
  color: var(--clr-primary-dark);
  font-size: rem(40);
  line-height: 1.6;
  letter-spacing: 0.05em;
  @include mq(sp) {
    font-size: sprem(22);
    margin-bottom: sprem(64);
  }
}
.home_message--btn a {
  padding: rem(24) rem(40);
  font-size: rem(24);
  border-radius: rem(8);
  min-width: inherit;
  @include mq(sp) {
    padding: sprem(16) sprem(24);
    font-size: sprem(18);
  }
  svg {
    width: rem(37);
    @include mq(sp) {
      width: sprem(24);
    }
  }
  &::before {
    content: none;
  }
  &::after {
    border-radius: rem(6)
  }
}

/* --- animation --- */
.home_message--deco {
  opacity: 0;
  transition: all 2s ease;
  &.deco2 {
    transition-delay: 0.3s;
  }
  &.deco3 {
    transition-delay: 0.6s;
  }
  &.deco4 {
    transition-delay: 0.9s;
  }
}
.home_message--photobox:not(.is-show) {
  .home_message--deco {
    &.deco1 {
      @include mq(sp, min, ps) {
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }
      @include mq(sp) {
        bottom: 50%;
        left: 50%;
        translate: -50% 50%;
      }
    }
    &.deco2 {
      @include mq(sp, min, ps) {
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }
      @include mq(sp) {
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }
    }
    &.deco3 {
      @include mq(sp, min, ps) {
        bottom: 50%;
        right: 50%;
        translate: 50% 50%;
      }
      @include mq(sp) {
        top: 50%;
        right: 50%;
        translate: 50% -50%;
      }
    }
    &.deco4 {
      @include mq(sp, min, ps) {
        bottom: 50%;
        right: 50%;
        translate: 50% 50%;
      }
      @include mq(sp) {
        bottom: 50%;
        right: 50%;
        translate: 50% 50%;
      }
    }
  }
}
.home_message--photobox.is-show {
  .home_message--deco {
    opacity: 1;
    translate: 0 0;
  }
}
.home_message--ttl {
  transition: all 1.2s ease;
}

/* home_about
********************************************** */
.home_about {
  background-image: url(/wp/wp-content/themes/standard_sass/images/bg-deco02.svg);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: rem(800) auto;
  @include mq(sp) {
    background-size: 50% auto;
  }
}
.home_about--content {
  gap: min(vw(56),56px);
  @include mq(sp) {
    gap: rem(24);
  }
}
.home_about--item {
  background-color: var(--clr-wht);
}
.about_item--txtarea {
  padding: rem(16) rem(32) rem(32);
  .mce-txt-attention {
    display: inline;
    padding-left: 0;
  }
}
.about_item--ttl {
  margin-bottom: rem(8);
  line-height: 1.6;
}
.about_item--attention {
  margin-top: rem(8);
  li {
    color: var(--clr-text-placeholder);
    font-size: rem(12);
    padding-left: 1em;
    text-indent: -1em;
    @include mq(sp) {
      font-size: sprem(10);
    }
  }
}
/* --- animation --- */
.home_about--content {
  .home_about--item {
    @include anime-scroll-before;
  }
  .home_about--item:nth-child(2) {
    transition-delay: 0.2s;
  }
  .home_about--item:nth-child(3) {
    transition-delay: 0.4s;
  }
}
.home_about--content.is-show {
  .home_about--item {
    @include anime-scroll-after;
  }
}

/* home_beginner
********************************************** */
.home_beginner--ttlarea {
  gap: min(vw(160), 160px);
}
.home_beginner--txt {
  flex: 1;
}
.home_beginner--content {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: vw(72);
  }
  @include mq(sp) {
    width: 76%;
    margin-inline: auto;
  }
}
.home_beginner--item {
  position: relative;
  @include center-flex;
  flex-direction: column;
  gap: rem(16);
  padding: vw(32) vw(24);
  background-color: var(--clr-bg-01);
  @include mq(sp) {
    padding: sprem(16);
    &:not(:last-child) {
      margin-bottom: sprem(56);
    }
  }
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    background-image: url(/wp/wp-content/themes/standard_sass/images/beginner-arrow.svg);
    background-size: vw(28) auto;
    background-repeat: no-repeat;
    background-position: center;
    @include mq(sp, min, ps) {
      top: 50%;
      right: 0;
      translate: 100% -50%;
      width: vw(72);
      height: 100%;
    }
    @include mq(sp) {
      width: sprem(56);
      height: sprem(56);
      bottom: 0;
      left: 50%;
      background-size: sprem(14) auto;
      translate: -50% 100%;
      rotate: 90deg;
    }
  }
}
.home_beginner--step {
  position: absolute;
  top: vw(-24);
  left: vw(-24);
  @include center-flex;
  width: vw(80);
  aspect-ratio: 1;
  font-size: vw(32);
  color: var(--clr-wht);
  background: var(--gradation01);
  border-radius: 50vw;
  @include mq(sp) {
    top: sprem(-12);
    left: sprem(-12);
    width: sprem(48);
    font-size: sprem(20);
  }
}
.home_beginner--ttl {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--clr-text-secondary);
  font-size: clamp(18px, vw(26), 24px);
  white-space: nowrap;
  @include mq(sp) {
    font-size: sprem(18);
  }
  .sub {
    color: var(--clr-primary-dark);
    font-size: clamp(16px, vw(20), 18px);
    letter-spacing: 0.05em;
    line-height: 1.6;
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
}

/* --- animation --- */
.home_beginner--content {
  .home_beginner--item {
    @include anime-scroll-before;
  }
  .home_beginner--item:nth-child(2) {
    transition-delay: 0.2s
  }
  .home_beginner--item:nth-child(3) {
    transition-delay: 0.4s
  }
  .home_beginner--item:nth-child(4) {
    transition-delay: 0.6s
  }
}
.home_beginner--content.is-show {
  .home_beginner--item {
    @include anime-scroll-after;
  }
}

/* home_cta
**************************************** */
.home_cta {
  @include mq(sp) {
    padding-block: sprem(56);
  }
}

/* home_lineup
********************************************** */
.home_lineup--box {
  position: relative;
  @include mq(sp, min, ps) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: min(vw(80), 80px);
  }
}
.home_lineup--ttlarea {
  @include mq(sp, min, ps) {
    position: sticky;
    top: calc(rem($header-hgt-fx) + rem(40));
  }
}
.home_lineup--content {
  @include mq(sp, min, ps) {
    flex: 1;
    max-width: rem(940);
  }
}
.home_lineup--list {
  .post-lineup:not(:last-child) {
    margin-bottom: rem(24);
  }
}
/* --- トップ用投稿調整 --- */
.home_lineup {
  .post_lineup--img {
    @include mq(sp) {
      right: sprem(2);
      max-width: sprem(140);
    }
  }
  .post_lineup--deco {
    top: min(vw(24), 24px);
    right: min(vw(32), 32px);
    @include mq(sp) {
      top: sprem(16);
      right: sprem(16);
    }
    img {
      max-height: clamp-vw(60);
      @include mq(sp) {
        max-height: sprem(38);
      }
    }
  }
  .post_lineup--use {
    font-size: rem(28);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
  .post_lineup--description {
    @include mq(sp) {
      padding-right: sprem(112);
    }
  }
}
/* --- animation --- */
.home_lineup--list {
  .post-lineup {
    @include anime-scroll-before;
  }
  .post-lineup:nth-child(2) {
    transition-delay: 0.3s;
  }
  .post-lineup:nth-child(3) {
    transition-delay: 0.6s;
  }
  .post-lineup:nth-child(4) {
    transition-delay: 0.9s;
  }
}
.home_lineup--list.is-show {
  .post-lineup {
    @include anime-scroll-after;
  }
}

/* home_feature
********************************************** */
.home_feature {
  background-image: url(/wp/wp-content/themes/standard_sass/images/bg-deco03.svg),
                    url(/wp/wp-content/themes/standard_sass/images/bg-deco04.svg);
  background-size: rem(1200) auto,
                    rem(800) auto;
  background-position: top right,
                        bottom left;
  background-repeat: no-repeat;
  @include mq(sp) {
    background-size: 70% auto,
                      50% auto;
  }
}

/* --- about_column --- */
.about_column {
  --clr-about: var(--clr-primary-light);
  --clr-about02: var(--clr-primary-dark);

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: rem(80);
  margin-bottom: rem(120);
  flex: 1;
  @include mq(sp) {
    flex-direction: column;
    gap: sprem(32);
    margin-bottom: sprem(72);
  }
}
.about_column.living {
  --clr-about: var(--clr-secondary-light);
  --clr-about02: var(--clr-secondary-dark);
}
.about_column--ttlarea {
  @include mq(sp, min, ps) {
    position: sticky;
    top: calc(rem($header-hgt-fx) + rem(40));
  }
}
.about_column--ttl {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include mq(sp) {
    align-items: flex-start;
  }
  .font-en {
    color: var(--clr-about02);
    font-size: rem(40);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
  .text {
    padding: rem(28) rem(20);
    font-size: rem(56);
    line-height: 1;
    background-color: var(--clr-wht);
    border: 4px solid var(--clr-about);
    box-sizing: content-box;
    @include mq(sp, min, ps) {//write-vで縦書きにすると小文字がずれるので強引に縦書きに…
      letter-spacing: 0;
      width: 1em;
    }
    @include mq(sp) {
      padding: sprem(8) sprem(16);
      font-size: sprem(24);
      letter-spacing: 0.03em;
      border: 3px solid var(--clr-about);
    }
    .write-v {
      @include mq(sp, min, ps) {
        @include write-v;
        margin-left: -0.1em;
      }
    }
  }
}
.about_column--content {
  @include mq(sp, min, ps) {
    padding-top: rem(80);
    max-width: rem(1216);
    flex: 1;
  }
}

/* --- about_content --- */
.about_content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sp) {
    flex-direction: column-reverse;
  }
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(pc) {
      margin-bottom: rem(128);
    }
    @include mq(sp) {
      margin-bottom: sprem(56);
    }
  }
  @include mq(sp, min, ps) {
    &:nth-child(odd) {
      flex-direction: row-reverse;
      .about_content--txtarea {
        left: 0;
      }
      .about_content--img img {
        right: 0;
        left: auto;
        transform-origin: top left;
        rotate: 2deg;
      }
    }
    &:nth-child(even) {
      .about_content--txtarea {
        right: 0;
      }
    }
  }
  @include mq(sp) {
    &:nth-child(odd) .about_content--img img {
      rotate: 2deg;
    }
  }

}
.about_content--img {
  position: relative;
  aspect-ratio: 640/426;
  width: perc(640,1216,'%');
  @include mq(sp) {
    width: 86%;
    margin-top: sprem(-24);
    z-index: 1;
  }
  img {
    position: absolute;
    left: 0;
    border: 8px solid var(--clr-about);
    box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
    transform-origin: top right;
    rotate: -2deg;
    @include mq(sp) {
      border: 4px solid var(--clr-about);
    }
  }
}
.about_content--txtarea {
  padding: clamp-vw(48);
  background-color: var(--clr-wht);
  @include mq(sp, min, ps) {
    position: absolute;
    width: rem(600);
    z-index: 10;
  }
  @include mq(sp) {
    padding: sprem(24) sprem(24) sprem(48);
  }
}
.about_content--ttl {
  margin-bottom: clamp-vw(24);
  color: var(--clr-text-secondary);
  font-size: rem(32);
  line-height: 1.6;
  letter-spacing: 0.05em;
  @include mq(sp) {
    font-size: sprem(20);
    margin-bottom: sprem(12);
  }
}
.about_content--txt {
  line-height: 1.8;
}

/* home_price
**************************************** */
.home_price--wrap {
  gap: min(vw(120), 120px);
}
.home_price--ttlarea {
  min-width: 260px;
  flex: 1;
}
.home_price--item {
  --clr-price: var(--clr-primary-dark);
  --grd-price: var(--gradation03);

  position: relative;
  @include center-flex;
  flex-direction: column;
  padding: min(vw(88), 88px) min(vw(40), 40px);
  border-radius: rem(16);
  background-color: var(--clr-bg-01);
  @include mq(sp) {
    padding: sprem(24);
  }
  &.subscription {
    --clr-price: var(--clr-secondary-dark);
    --grd-price: var(--gradation04);
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: rem(16);
    border: 3px solid var(--clr-price);
    pointer-events: none;
    opacity: 0;
    transition: var(--transit-default);
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
}
.home_price--copy {
  padding: rem(6) rem(24);
  margin-bottom: rem(16);
  color: var(--clr-price);
  font-size: clamp-vw(20);
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-align: center;
  background-color: var(--clr-wht);
  border-radius: 50vw;
  @include mq(sp) {
    font-size: sprem(16);
    margin-bottom: sprem(8);
  }
}
.home_price--subttl {
  color: var(--clr-text-secondary);
  font-size: clamp-vw(40);
  line-height: 1.6;
  letter-spacing: 0.05em;
  margin-bottom: rem(16);
  @include mq(sp) {
    font-size: sprem(24);
    margin-bottom: sprem(8);
  }
}
.home_price--txt {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.home_price--btn a {
  display: flex;
  align-items: center;
  gap: rem(24);
  font-size: rem(18);
  .arrow {
    position: relative;
    width: rem(40);
    aspect-ratio: 1;
    background: var(--grd-price);
    border-radius: 50vw;
    transition: var(--transit-default);
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background-color: var(--clr-wht);
      border-radius: 50vw;
      transition: var(--transit-default);
      opacity: 0;
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      width: rem(8);
      aspect-ratio: 8/12;
      clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
      background-color: var(--clr-wht);
      z-index: 2;
    }
  }
  &:hover {
    color: var(--clr-price);
    .arrow::before {
      opacity: 1;
    }
    .arrow::after {
      background-color: var(--clr-price);
    }
  }
}
/* --- animation --- */
.home_price--item .subscription {
  transition-delay: 0.3s;
}

/* home_bottom--wrap
**************************************** */
.home_bottom--wrap {
  overflow: hidden;
  .footer_cta {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 50%;
      width: vw(1200);
      aspect-ratio: 1200/614;
      background-image: url(/wp/wp-content/themes/standard_sass/images/bg-deco05.svg);
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: vw(1200) auto;
      @include mq(sp) {
        width: 100%;
        bottom: sprem(80);
        background-size: 97% auto;
      }
    }
  }
}
@include mq(sp, min, ps) {
  .home_bottom--photo {
    position: absolute;
    top: 0;
    right: rem(-224);
    display: flex;
    gap: rem(24);
    rotate: 20deg;
    transform-origin: top left;
    opacity: 0.2;
    z-index: -1;
  }
  .scroll_photo--box.left {
    animation: scroll-up 60s linear infinite;
  }
  .scroll_photo--box.right {
    animation: scroll-down 60s linear infinite;
  }
  .scroll_photo--item {
    width: rem(400);
    aspect-ratio: 400/270;
    margin-block: rem(24);
  }
}
@include mq(sp) {
  .home_bottom--photo {
    position: absolute;
    top: sprem(24);
    left: sprem(-64);
    display: flex;
    flex-direction: column;
    gap: sprem(12);
    opacity: 0.5;
    z-index: -1;
    overflow: hidden;
    rotate: 4deg;
    transform-origin: left;
  }
  .scroll_photo--box.left {
    animation: scroll-left 60s linear infinite;
  }
  .scroll_photo--box.right {
    animation: scroll-right 60s linear infinite;
  }
  .scroll_photo--box,
  .scroll_photo {
    display: flex;
    white-space: nowrap;
    gap: sprem(12);
    width: max-content;
  }
  .scroll_photo--item {
    width: sprem(168);
    aspect-ratio: 400/270;
  }
}

/* home_voice
**************************************** */
.home_voice {
  padding-bottom: rem(200);
  @include mq(sp) {
    padding-block: sprem(320) sprem(80);
  }
}
.home_voice--wrap {
  gap: min(vw(120), 120px);
  @include mq(sp) {
    gap: sprem(40);
  }
}
.home_voice--ttlarea {
  flex: 1;
}

/* --- animation --- */
.home_voice--content {
  .home_voice--item {
    @include anime-scroll-before;
    &:nth-child(2) {
      transition-delay: 0.3s;
    }
    &:nth-child(3) {
      transition-delay: 0.6s;
    }
  }
}
.home_voice--content.is-show {
  .home_voice--item {
    @include anime-scroll-after;
  }
}