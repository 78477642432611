@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */

/* footer_cta
**************************************** */
.footer_cta {
  position: relative;
  z-index: 10;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: calc(50% + 1px);
    background: var(--gradation03);
    @include mq(sp) {
      height: sprem(80);
    }
  }
}

/* footer
**************************************** */
.footer {
  padding-block: rem(120);
  color: var(--clr-wht);
  background: var(--gradation03);
  @include mq(sp) {
    padding-block: sprem(64) sprem(16);
  }
  a {
    color: var(--clr-wht);
    &:hover {
      color: var(--clr-secondary-light);
    }
  }
}
.footer--logo {
  margin-bottom: rem(48);
  @include mq(sp) {
    text-align: center;
  }
}
.footer--logo a {
  width: clamp(230px, vw(400), 400px);
  @include mq(sp) {
    width: 90%;
  }
}

/* footer_top
********************************************** */
.footer_top {
  padding-bottom: rem(48);
  border-bottom: 1px solid var(--clr-wht);
}
.footer_top--nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: rem(64);
  @include mq(sp) {
    flex-direction: column;
  }
}
/* ---fnav --- */
.fnav {
  display: flex;
  align-items: flex-start;
  gap: min(vw(160),160px);
  flex: 1;
  @include mq(pc) {
    gap: vw(120);
  }
  @include mq(sp) {
    flex-wrap: wrap;
    width: 100%;
    gap: rem(32);
  }
}
.fnav--menu {
  @include mq(sp) {
    width: calc((100% - rem(32)) / 2);
  }
}
.fnav--menu a {
  display: block;
  &[href="javascript:void(0);"] {
    pointer-events: none;
  }
}
.fnav--menu .menu-item {
  &:not(:last-child) {
    @include mq(sp) {
      margin-bottom: rem(4);
    }
  }
}

/* --- 1階層目のメニュー --- */
.fnav > .fnav--menu > .menu-item > a {
  @include mq(sp, min, ps) {
    font-size: rem(18);
    margin-bottom: rem(16);
  }
}
.sub-menu {
  @include mq(sp) {
    margin-top: sprem(4);
  }
}
.sub-menu > li > a {
  position: relative;
  padding-left: rem(18);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.6em;
    bottom: auto;
    width: rem(6);
    height: auto;
    aspect-ratio: 6/10;
    clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
    background-color: currentColor;
    translate: 0 0;
    opacity: 1;
  }
}

/* --- footer_cta--btn --- */
.footer_cta--btn {
  @include mq(sp) {
    margin-inline: auto;
  }
}
.footer_cta--btn .btn a {
  @include center-flex;
  gap: rem(8);
  width: rem(320);
  height: rem(72);
  padding: rem(16) rem(32);
  border: 1px solid var(--clr-wht);
  border-radius: rem(8);
  span {
    position: relative;
    z-index: 1;
  }
  svg {
    width: rem(26) !important;
    fill: transparent;
  }
  &::after {
    border-radius: rem(6);
  }
  &:hover {
    color: var(--clr-primary-dark);
  }
}
.footer_cta--btn .btn-cta {
  @include mq(sp) {
    margin-bottom: sprem(12);
  }
}
.footer_cta--btn .btn-cta a {
  svg {
    width: rem(29) !important;
  }
  &:hover {
    color: var(--clr-secondary-dark);
  }
}

/* ---sns_area --- */
.sns_area {
  margin-bottom: rem(16);
}
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  fill: currentColor;
  @include rect(24);
  &.icon-x {
    @include rect(22);
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding-top: rem(40);
}

/* --- fnav--bottom --- */
.fnav--bottom {
  a {
    position: relative;
    display: flex;
    align-items: center;
    gap: rem(8);
    font-size: rem(14);
  }
  a[target*="_blank"] {
    transition: initial;
    &::after {
      content: '';
      display: block;
      width: rem(12);
      aspect-ratio: 12/11;
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/icon-blank.svg);
      background-repeat: no-repeat;
      background-size: contain;
      filter: brightness(0) invert(1);
    }
    &:hover::after {
      filter: brightness(1) invert(0);
    }
  }
  @include mq(sp) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: sprem(8) sprem(24) !important;
    margin-bottom: sprem(8);
    padding-inline: sprem(24);
  }
}

.copyright {
  gap: 1em;
  justify-content: center;
  @include mq(sp) {
    gap: 0.5em;
    align-items: center;
    flex-direction: column;
    opacity: 0.5;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: 0.5;
  @include mq(sp) {
    display: block;
  }
  &:hover {
    opacity: 1;
  }
}
