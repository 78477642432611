@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27,12);
}
.lps_sec {
  position: relative;
  &:where(:nth-child(even)) {
    background-color: var(--clr-wht);
    .bg-wht {
      background-color: var(--clr-bg-01);
    }
  }
  &:nth-child(8n - 7),
  &:nth-child(8n - 5),
  &:nth-child(8n - 3),
  &:nth-child(8n - 1) {
    background-repeat: no-repeat;
  }
  &:nth-child(8n - 7) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-deco01.svg);
    background-position: right bottom;
    background-size: vw(1200) auto;
    @include mq(sp) {
      background-size: 75% auto;
    }
  }
  &:nth-child(8n - 5) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-deco02.svg);
    background-position: left bottom;
    background-size: vw(800) auto;
    @include mq(sp) {
      background-size: 60% auto;
    }
  }
  &:nth-child(8n - 3) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-deco03.svg);
    background-position: right bottom;
    background-size: vw(1200) auto;
    @include mq(sp) {
      background-size: 75% auto;
    }
  }
  &:nth-child(8n - 1) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-deco04.svg);
    background-position: left bottom;
    background-size: vw(800) auto;
    @include mq(sp) {
      background-size: 60% auto;
    }
  }
  &:last-child {
    background-image: none !important;
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_column
**************************************** */
.lps_column:has(.lps_side) {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: clamp-vw(320) 1fr;
  }
}
.lps_contents {
  overflow: hidden;
}

/* side_anchor
**************************************** */
.side_anchor {
  position: sticky;
  top: rem($header-hgt-fx);
  padding: clamp-vw(56);
  max-height: calc(100dvh - rem($header-hgt-fx));
  overflow: auto;
  @include mq(sp) {
    padding: sprem(40) sprem(40) sprem(48);
  }
  &::-webkit-scrollbar {
    width: rem(8);
    background: var(--clr-mono-01);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--clr-mono-03);
    border-radius: 50vw;
  }
}
.side_anchor--ttl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(32);
  margin-bottom: rem(32);
  color: var(--clr-primary-dark);
  font-size: rem(32);
  &::after {
    content: '';
    display: block;
    width: rem(40);
    height: rem(4);
    background-color: var(--clr-primary-light);
    border-radius: 50vw;
  }
  @include mq(sp) {
    display: none;
  }
}
.side_anchor--list {
  li:not(:last-child) {
    margin-bottom: rem(32);
  }
  a {
    position: relative;
    display: block;
    padding-right: rem(24);
    font-size: rem(18);
    letter-spacing: 0.05625em;
    line-height: 1.6;
    &.current {
      color: var(--clr-primary-default);
    }
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: rem(10);
      aspect-ratio: 10/6;
      clip-path: polygon(0 1.5px, 0 0, 50% calc(100% - 1.5px), 100% 0, 100% 1.5px, 50% 100%);
      translate: 0 -50%;
      background-color: var(--clr-primary-default);
      @include mq(sp) {
        width: sprem(10);
      }
    }
  }
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(64);
    }
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}