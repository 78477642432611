@use '../abstracts' as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* related_page
********************************************** */
.lps_sec.section-related_page {
  padding-block: rem(120) rem(360);
  background-color: transparent;
  @include mq(sp, min, ps) {
    margin-bottom: rem(-240);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-deco05.svg);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: vw(1200) auto;
  }
  @include mq(sp) {
    padding-block: sprem(24) sprem(80);
  }
  &:not(:has(.inner)) {
    padding-top: 0;
    background-image: inherit;
  }
}
.section-related--ttl {
  @include center-flex;
  gap: rem(16);
  margin-inline: auto;
  letter-spacing: 0.03em;
  &::before,
  &::after {
    content: '';
    display: block;
    width: rem(4);
    height: rem(40);
    background-color: var(--clr-primary-light);
    border-radius: 50vw;
  }
}
.related_page--list {
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(24);
  }
  @include mq(sp) {
    li:not(:last-child) {
      margin-bottom: sprem(8);
    }
  }
}
.related_page--list a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: clamp-vw(40);
  line-height: var(--line-height-hdr);
  color: var(--clr-wht);
  background-color: var(--clr-wht);
  border-radius: rem(8);
  overflow: hidden;
  @include mq(sp) {
    padding: sprem(20);
  }
  .arrow {
    position: relative;
    @include rect(48);
    background: var(--gradation03);
    border-radius: 50vw;
    overflow: hidden;
    @include mq(sp) {
      @include sprect(32);
    }
    &::after {
      content: '';
      position: absolute; 
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      @include rect(8,16);
      background-color: var(--clr-wht);
      clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%, calc(100% - 2px) 50%);
      @include mq(sp) {
        width: sprem(10);
      }
    } 
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: rem(8);
    border: 2px solid var(--clr-primary-dark);
    opacity: 0;
    transition: var(--transit-default);
    pointer-events: none;
  }
  &:hover {
    &::after {
      opacity: 1;
    }
    p,
    .font-en {
      color: var(--clr-primary-dark);
    }
    .related_page--bg {
      opacity: 0.3;
    }
  }
}
.related_page--list p{
  color: var(--clr-text-secondary);
  font-size: rem(24);
  margin-bottom: rem(8);
  transition: var(--transit-default);
  @include mq(sp) {
    font-size: sprem(18);
    margin-bottom: 0;
  }
}
.related_page--list .font-en {
  display: block;
  font-size: rem(18);
  color: var(--clr-primary-dark);
  transition: var(--transit-default);
  &::first-letter {
    text-transform: uppercase;
  }
  @include mq(sp) {
    font-size: sprem(12);
  }
}
.related_link--text {
  position: relative;
  z-index: 10;
}
.related_page--bg {
  position: absolute;
  inset: 0;
  background-color: lightgray;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  z-index: 0;
  transition: var(--transit-default);
}