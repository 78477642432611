@use "../abstracts" as *;

/* lps_parts--voice
********************************************** */

.voice_content {
  position: relative;
  padding-top: rem(24);
  padding-inline: rem(64);
  background-color: var(--clr-wht);
  overflow: hidden;
  box-shadow: 4px 4px 8px 0px rgba(86, 72, 60, 0.20);
  @include mq(sp, min, ps) {
    width: clamp-vw(920);
    max-width: 65%;
  }
  @include mq(sp) {
    padding-top: sprem(32);
    padding-inline: sprem(24);
  }
  &.lps_voice_content {
    box-shadow: 2px 2px 16px 0px rgba(86, 72, 60, 0.20);
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(24);
    background-image: url(/wp/wp-content/themes/standard_sass/images/voice-deco.svg);
    background-repeat: repeat-x;
    background-position: center top;
    @include mq(sp) {
      aspect-ratio: 340/31;
      width: 100%;
      height: auto;
      background-image: url(/wp/wp-content/themes/standard_sass/images/voice-deco_sp.svg);
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }
}

.voice_item {
  padding-block: rem(64);
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: rem(96) auto;
    gap: rem(32);
  }
  @include mq(sp) {
    position: relative;
    padding-block: sprem(32);
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--clr-bg-02);
  }
}
.voice_item--img {
  width: rem(96);
  aspect-ratio: 1;
  border-radius: 50vw;
  @include mq(sp) {
    position: absolute;
    top: sprem(32);
    left: 0;
    width: sprem(56);
  }
}
.voice_item--person {
  display: grid;
  align-items: center;
  padding-inline: rem(16);
  margin-bottom: rem(16);
  width: fit-content;
  height: rem(32);
  font-size: rem(18);
  line-height: 1.6;
  color: var(--clr-text-secondary);
  background-color: var(--clr-bg-01);
  border-radius: 50vw;
  @include mq(sp) {
    position: absolute;
    top: calc(sprem(32) + sprem(56)); //voice_itemの上余白 ＋ アイコンの高さ
    left: sprem(72);
    translate: 0 -100%;
    font-size: sprem(14);
  }
}
.voice_item--txt {
  line-height: 1.6;
  letter-spacing: 0.05em;
  @include mq(sp) {
    margin-top:  calc(sprem(56) + sprem(16)); //アイコンの高さ＋余白
    font-size: sprem(14);
  }
  .bold {
    color: var(--clr-secondary-dark);
    font-weight: 500;
  }
}
.home_voice .voice_item--txt {
  font-size: rem(20);
  @include mq(sp) {
    font-size: sprem(14);
  }
}