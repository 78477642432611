@use "../abstracts" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes scroll-up {
  0% { translate: 0 -20%; }
  100% { translate: 0 -80%; }
}
@keyframes scroll-down {
  0% { translate: 0 -80%; }
  100% { translate: 0 -20%; }
}
@keyframes scroll-left {
  0% { translate: -20% 0; }
  100% { translate: -80% 0; }
}
@keyframes scroll-right {
  0% { translate: -80% 0; }
  100% { translate: -20% 0; }
}