@use '../abstracts' as *;

/* sitemap
*************************************************** */
.sitemap--menu {
  @include mq(sp, min, ps) {
    column-count: 2;
    gap: 5%;
  }
}
.sitemap--menu > li {
  break-inside: avoid;
}
.sitemap--menu > li > a {
  font-size: rem(18);
  border-bottom: 1px solid var(--clr-mono-03);
  padding-top: .5em;
  padding-bottom: .5em;
  margin-bottom: .5em;
}
.sitemap--menu a,
.sitemap--menu span {
  display: block;
}
.sitemap--menu a {
  position: relative;
  &:hover {
    color: var(--clr-primary-default);
  }
}
.sitemap_sub_nav--blc {
  padding-left: 1em;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.sitemap--tax {
  border-bottom: 1px dotted var(--clr-mono-03);
  padding-top: .5em;
  padding-bottom: .5em;
  margin-bottom: .5em;
}
.sitemap_sub_nav a {
  padding-top: .5em;
  padding-bottom: .5em;
  margin-inline-start: 1.25em;
  &::before {
    content: "";
    float: inline-start;
    inline-size: rem(6);
    aspect-ratio: 1;
    margin-block: calc((1lh - rem(6)) / 2);
    margin-inline-start: -1.25em;
    border-top: 1px solid var(--clr-primary-default);
    border-right: 1px solid var(--clr-primary-default);
    transform: rotate(45deg);
  }
}